<div class="banner inner-shadow-top" id="dbtop">
    <div class="container vertical-align full-height-vh banner-background-container"
         [ngStyle]="{'padding-top': heightService.getHeight() + 40 + 'px'}">
        <div class="banner-background"></div>
        <div class="row"></div>
        <div class="row justify-content-around">
            <div class="col-12 col-sm-10">
                <div class="titles">About UniHack</div>
            </div>
            <div class="col-12 col-sm-10 col-md-5 col-lg-5 col-xl-5 offset-sm-1 justify-content-center">
                <div class="description text-md-left">

                    <div class="d-block d-sm-none">
                        <div [innerHTML]="settings.homepageEventDetails"></div>
                    </div>
                    <div class="d-none d-sm-block">
                        <div [innerHTML]="settings.homepageEventDescription"></div>
                    </div>
                </div>
            </div>
            <div class="col-1"></div>
            <div class="col-1"></div>
            <div class="col-12 col-sm-11 col-md-4 justify-content-center padding-left mt-3">
                <div>
                   <!-- <div>
                        <div class="heading">2000 EUR</div>
                        <div class="subtitles mb-3 mb-md-5">Grand Prize</div>
                    </div>-->
                    <div>
                        <div class="heading">48h</div>
                        <div class="subtitles mb-3 mb-md-5">non-stop coding</div>
                    </div>
                    <div class="d-none d-md-block">
                        <div class="heading">400+</div>
                        <div class="subtitles mb-3 mb-md-5">participants</div>
                    </div>
                    <div>
                        <div class="heading">Free</div>
                        <div class="subtitles mb-3 mb-md-5">attendance</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <div class="arrow">
                    <a
                            [routerLink]="'/details'"
                            pageScroll
                            [pageScrollOffset]="66"
                            [pageScrollAdjustHash]="true"
                            [pageScrollDuration]="500"
                            href="#tracks">
                        <div class="text-find-out mt-4">Find out more!</div>
                        <img src="/assets/images/arrow.svg"/>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
