<div class="details col inner-shadow-top" id="details">
  <div class="container-fluid">
    <div class="row">
      <ul class="det-list col-12">
        <li>48h of non-stop coding</li>
        <li>400+ international participants</li>
        <li> civic tracks</li>
        <li>innovative ideas & civic impact</li>
<!--        <li>civic impact</li>-->
        <li>experienced mentors, speakers and trainers</li>
        <li>awesome prizes</li>
        <li>no participation fee</li>
      </ul>
      <div class="col text-center">
        <button
        class="btn btn-primary"
        href="#dbtop"
        [routerLink]="'/details'"
        pageScroll
        [pageScrollDuration]="500"
        [pageScrollAdjustHash]="true">More event details</button>
      </div>
    </div>
    <div class="circle dim30"></div>
    <div class="circle dim40"></div>
    <div class="circle dim50"></div>
    <div class="circle dim60"></div>
    <div class="circle dim70"></div>
  </div>
</div>
