<div class="tracks-background component inner-shadow-top" id="tracks">
    <div class="container">
        <div class="row justify-content-around">
            <div class="col-12 col-sm-10">
                <div class="titles">Tracks</div>
            </div>
            <div class="col-12 col-sm-10 col-md-5 col-lg-5 col-xl-5 offset-sm-1 justify-content-center">
                <div class="description-text text-md-left">
                    <p>
                        Tracks, tracks, tracks! You keep hearing about
                        them, but maybe you don’t really know what they
                        are. Let us explain! Tracks are different themes that
                        your project can be categorized under.
                    </p>
                    <p>
                        They will be
                        divided among the sponsors, who will also provide
                        the prizes and support for their specific track.
                        Your project can be eligible to receive awards for
                        one or more tracks, depending on the technologies
                        they use.
                    </p>
                    <p>
                        Pretty exciting, right?
                    </p>
                    <p>
                      <!--  <button
                                class="btn btn-primary purple-button"
                                href="#trtop"
                                [routerLink]="'/tracks'"
                                pageScroll
                                [pageScrollDuration]="500"
                                [pageScrollAdjustHash]="true">See more</button>-->
                    </p>
                </div>
            </div>
            <div class="col-1"></div>
            <div class="col-1"></div>
            <div class="col-12 col-sm-11 col-md-4 justify-content-center mt-4 mb-4 padding">
                <div class="offset-lg-0">
                   <!-- <div>
                        <div class="heading mt-sm-1">3</div>
                        <div class="subtitles mb-3 mb-md-5">technical tracks</div>
                    </div>
                    <div>
                        <div class="heading">1</div>
                        <div class="subtitles mb-3 mb-md-5">civic track</div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</div>
