import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-tracks',
    templateUrl: './tracks.component.html',
    styleUrls: ['./tracks.component.scss'],
})
export class TracksComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
